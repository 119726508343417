<template>
  <transition>
    <transition :name="routerTransition" mode="out-in">
      <router-view />
    </transition>
  </transition>
</template>
<script>
import useAppConfig from "@core/app-config/useAppConfig";
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions([
      "getClassesList",
      "getUnitsOptions",
      "getSubjectOptions",
      "getSettings",
      "getRates",
      "getColors",
      "getAwsAccess",
    ]),
  },
  created() {
    this.getClassesList();
    this.getUnitsOptions();
    this.getSubjectOptions();
    this.getSettings();
    this.getColors();
    this.getRates();
    this.getAwsAccess();
  },
  setup() {
    const { routerTransition } = useAppConfig();
    return {
      // App Config
      routerTransition,
    };
  },
};
</script>